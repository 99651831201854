// STRING AND CONTENT STORAGE
export const content = {
  language: "en",
  en: {
    title: "GROUNDLOOP",
    subtitle: "2500",
    back: "Back to Lisa Reihana",
    backPage: "https://companion.art/playlists/groundloop",
    messageDismiss: "Dismiss",
    messageReload: "Reload",
    messages: {
      locationPermissionDenied: {
        text: "This is an audio-only experience that syncs with the artwork GROUNDLOOP.<br /><br /> You will need to allow location services in order to access the experience. This information will only be used while you’re listening.",
        icon: "locpin",
        isDismissable: false,
        hasNavigation: true,
      },
      locationRegionInvalid: {
        text: "You are out of the artwork location. Please move within range of artwork. ",
        icon: "",
        isDismissable: true,
        hasNavigation: true,
      },
      audioSyncReady: {
        text: "This is an audio-only experience that syncs with the artwork GROUNDLOOP.<br /><br /> Your experience will be ready soon.",
        icon: "headphones",
        isDismissable: true,
        hasNavigation: false,
      },
    },
  },
};
