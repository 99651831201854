<template>
  <nav class="nav">
    <a class="nav__back" :href="backPage" target="_self">
      <inline-svg
        class="nav__back__icon"
        :src="require('../assets/img/back.svg')"
        title="back"
      ></inline-svg>
      <p class="nav__back__text">{{ backText }}</p>
    </a>
  </nav>
</template>

<script>
import { content } from "../js/contentStore.js";

export default {
  name: "MainNav",
  data() {
    return {
      backText: content[content.language].back,
      backPage: content[content.language].backPage,
    };
  },
};
</script>

<style lang="scss">
.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid $white-fade-2;

  &__back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;

    &__icon {
      flex: 1 0 auto;
      stroke: $white-fade;
      margin-right: 0.5rem;
    }

    &__text {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $white-fade;
    }
  }
}
</style>
