<template>
  <div v-if="showDebug" id="debug">
    Debug:
    <router-link to="/">Loading</router-link>
    <router-link to="/home">Home</router-link>
  </div>
  <MainNav />
  <router-view v-slot="{ Component }">
    <transition :name="$route.meta.transition" :mode="$route.meta.transMode">
      <component
        @getlocation="getLocation"
        @checkregion="checkRegion"
        @togglemodal="toggleModal"
        @openmodal="openModal"
        @closemodal="closeModal"
        :is="Component"
      />
    </transition>
  </router-view>
  <MainModal ref="modal" />
</template>

<script>
import MainNav from "./components/MainNav.vue";
import MainModal from "./components/MainModal.vue";
import preload from "./js/preload.js";

export default {
  name: "App",
  components: {
    MainNav,
    MainModal,
  },
  data() {
    return {
      showDebug: false,
      locationCallback: () => {},
      location: null,
    };
  },
  methods: {
    getLocation(callback) {
      this.locationCallback = callback;
      if (!navigator.geolocation) {
        //console.log("geolocation unsupported");
        this.openModal("[geolocation not supported message]");
      } else {
        navigator.geolocation.getCurrentPosition(
          this.locationSuccess,
          this.locationError
        );
      }
    },
    locationSuccess(position) {
      this.locationCallback(position);
      this.location = position;
      //console.log(this.location);
    },
    locationError() {
      this.locationCallback(false);
    },
    async checkRegion(callback, location) {
      var inRange = false;
      //console.log(location);
      var coords = location.coords;
      var response = await fetch(process.env.VUE_APP_GEOLOCK_ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          latitude: coords.latitude,
          longitude: coords.longitude,
          action: "getSite",
        }),
      });

      var parsedResponse = await response.json();
      if (parsedResponse["status"] == 200) {
        inRange = true;
      }
      // WIP EVENT TO CHECK/FETCH REGION
      callback(inRange);
    },
    toggleModal() {
      this.$refs.modal.toggle();
    },
    openModal(msg, callback = () => {}) {
      this.$refs.modal.open(msg, callback);
    },
    closeModal() {
      this.$refs.modal.close();
    },
    mediaPreload() {
      preload.media.forEach((media) => {
        const image = new Image();
        image.src = require("@/assets/" + media.path);
      });
    },
    toggleDebug() {
      this.showDebug = !this.showDebug;
    },
  },
  beforeRouteEnter() {
    //console.log(this.$route);
  },
  mounted() {
    window.toggleDebug = this.toggleDebug;
    // need proper check to ensure location perm and data is setup and not expired
    /*     if (!this.location) {
      this.$router.push({ name: "Loading" });
    } */
    this.mediaPreload();
  },
};
</script>

<style lang="scss">
@import "@/styles/reset.scss";
@import "@/styles/typography.scss";

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#debug {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  text-align: center;
  pointer-events: none;
  z-index: 1000;
  font-size: 1rem;
  color: black;

  a {
    font-weight: bold;
    color: black;
    pointer-events: auto;
    margin-left: 4px;
    font-size: 1rem;

    &.router-link-exact-active {
      color: darkgray;
    }
  }
}

// router transitions
.router-trans {
  // ENTER ----------------------------------------------------
  &-enter {
    &-from {
      transform: translateZ(0.01px);
    }

    // present for from and to
    &-active {
      transition: opacity $d-short ease-in-out;
    }

    &-to {
      transform: translateZ(0);
    }
  }

  // LEAVE ----------------------------------------------------
  &-leave {
    &-from {
      transform: translateZ(0);
    }

    // present for from and to
    &-active {
      transition: opacity $d-short ease-in-out;
    }

    &-to {
      transform: translateZ(0.01px);
    }
  }
}
</style>
