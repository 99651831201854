<template>
  <section class="modal">
    <div
      @click="bgDismiss"
      class="modal__bg"
      :class="{
        'modal__bg--open': isOpen && showBackdrop,
        'modal__bg--dismiss': msg.isDismissable,
      }"
    ></div>
    <div class="modal__body" :class="{ 'modal__body--open': isOpen }">
      <div class="modal__body__content">
        <p class="modal__body__content__text" v-html="msg.text || ''"></p>
        <inline-svg
          v-if="msg.icon"
          class="modal__body__content__icon"
          :class="`modal__body__content__icon--${msg.icon}`"
          :src="require(`../assets/img/${msg.icon}.svg`)"
          :title="msg.icon"
        ></inline-svg>
      </div>
      <div class="modal__body__controls">
        <button
          v-if="msg.isDismissable"
          @click.prevent="close"
          class="modal__body__controls__btn"
        >
          {{ dismissText }}
        </button>
        <a
          :href="backPage"
          target="_self"
          v-if="msg.hasNavigation"
          class="modal__body__controls__btn modal__body__controls__btn--back"
        >
          {{ backText }}
        </a>
        <button
          v-if="msg.hasNavigation"
          class="modal__body__controls__btn modal__body__controls__btn--reload"
          @click="reload"
        >
          {{ reloadText }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { content } from "../js/contentStore.js";
export default {
  name: "MainModal",
  data() {
    return {
      isOpen: false,
      showBackdrop: true,
      msg: {},
      dismissText: content[content.language].messageDismiss,
      backText: content[content.language].back,
      reloadText: content[content.language].messageReload,
      backPage: content[content.language].backPage,
      callback: null,
    };
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    close() {
      this.isOpen = false;
      if (this.callback) this.callback();
    },
    open(msg, callback) {
      this.msg = msg;
      this.isOpen = true;
      this.callback = null;
      if (callback) this.callback = callback;
    },
    bgDismiss() {
      if (!this.msg.isDismissable) return;
      this.close();
    },
    reload() {
      location.reload();
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__bg {
    width: 100%;
    height: 100%;
    z-index: $z-modal-b;
    background-color: $black-fade;
    pointer-events: none;
    opacity: 0;
    transition: opacity $d-standard linear;

    &--open {
      opacity: 1;
      pointer-events: auto;
    }

    &--dismiss {
      background-color: transparent;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    z-index: $z-modal;
    pointer-events: auto;
    min-height: 12rem;
    padding: 1rem;
    background-color: $white;
    color: $black;
    transform: translateY(100%);
    visibility: hidden;
    transition: transform $d-standard $ease-in-out,
      visibility 0ms linear $d-standard;

    &--open {
      transform: translateY(0);
      visibility: visible;
      transition: transform $d-standard $ease-in-out;
    }

    &__content {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 2rem;

      &__text {
      }

      &__icon {
        flex: 1 0 auto;
        width: 2rem;
        max-width: 2rem;
        height: fit-content;
        max-height: 2rem;
        margin-left: auto;

        &--locpin {
          fill: $black;
          stroke: none;
        }

        &--headphones {
          fill: $black;
          stroke: none;
        }
      }
    }

    &__controls {
      display: flex;
      flex-direction: column;
      // gap: 1rem;
      width: 100%;
      margin-top: auto;

      &__btn {
        display: block;
        width: fit-content;
        text-align: left;
        text-decoration: underline;
        text-underline-offset: 4px;
        cursor: pointer;

        &--back,
        &--reload {
          width: 100%;
          border: 2px solid $black;
          padding: 0.75rem;
          text-decoration: none;
          transition: background-color $d-short $ease-in-out,
            color $d-short $ease-in-out;

          &:hover,
          &:active {
            background-color: $black;
            color: $white;
          }
        }

        &--back {
          margin-bottom: 1rem;
        }

        &--reload {
          background-color: $black;
          color: $white;

          &:hover,
          &:active {
            background-color: $white;
            color: $black;
          }
        }
      }
    }
  }
}
</style>
