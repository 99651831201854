<template>
  <div class="home">
    <header class="home__header">
      <h3 class="home__header__subtitle">
        <inline-svg
          class="home__header__subtitle__icon"
          :src="require('../assets/img/headphones.svg')"
          title="headphones"
        ></inline-svg>
        <p class="home__header__subtitle__text">{{ subtitleText }}</p>
      </h3>
      <h1 id="text" class="home__header__title">{{ titleText }}</h1>
    </header>

    <!-- debug -->
    <div
      v-if="$root.showDebug"
      ref="debug"
      style="
        z-index: -1;
        position: relative;
        font-size: 14px;
        overflow-y: auto;
        width: 20rem;
        height: 20rem;
        word-break: keep-all;
      "
    >
      received: {{ time.toFixed(3) }} <br />
      received (running): {{ syncedRunningTime.toFixed(3) }} <br />
      recieved (running, relative): {{ calcRelativeTime().toFixed(3) }}
      <br /><br />
      converted time: {{ convertTime(time) }} <br />
      <br /><br />
      file currentTime:
      {{
        processData.length && processData[fileIndex].trueCurrentTime
          ? processData[fileIndex].trueCurrentTime.toFixed(2)
          : ""
      }}/{{
        processData.length && processData[fileIndex].duration
          ? processData[fileIndex].duration.toFixed(2)
          : ""
      }}
      <br /><br />
      delta (pos means behind): {{ delta.toFixed(2) }}<br /><br />
      avgDelta (pos means behind): {{ avgDelta.toFixed(2) }}<br />
      resyncDelayDelta: {{ resyncDelayDelta.toFixed(2) }}<br />
      {{ avgDeltaArr }}
      <br /><br />
      Playing {{ fileIndex }} at
      <span style="color: red"
        >x{{
          sourceArray[fileIndex]
            ? parseFloat(sourceArray[fileIndex].playbackRate).toFixed(2)
            : ""
        }}
        speed</span
      >
      <br /><br />
    </div>

    <div class="home__image">
      <img
        src="@/assets/img/image1.jpg"
        alt="GROUNDLOOP"
        class="home__image__img"
      />
    </div>

    <div class="home__controls">
      <button
        @click="toggleButton"
        ref="button"
        class="home__controls__btn"
        :class="{
          'home__controls__btn--stop': isPlaying,
          'home__controls__btn--loading':
            !isReady ||
            !socket ||
            !isConnected ||
            (!time && time !== 0) ||
            (processData[fileIndex] && processData[fileIndex].isStuck),
          'home__controls__btn--tap': buttonTap,
        }"
        :aria-label="isPlaying ? 'stop' : 'play'"
      >
        <div class="home__controls__btn__icon"></div>
        <div class="home__controls__btn__spinner">
          <svg class="home__controls__btn__spinner__svg" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import * as fflate from "fflate";
import { content } from "../js/contentStore.js";

export default {
  name: "Home",
  data() {
    return {
      isReady: false,
      started: false, // is initialised, all time operations
      isPlaying: false, // is actually playing, audio operations
      socket: "",
      time: 0, // received time snapshot
      syncedRunningTime: 0, // takes snapshot and appends time since last update
      lastUpdate: 0,
      delta: 0,
      avgDelta: 0,
      avgDeltaArr: [],
      resyncDelayDelta: 0, // certain devices take a variable amount of time to update currentTime, if an uncleared avgDeltaArr is constantly full of high values, offset by that too
      resyncDelayDeltaArr: [],
      updateInterval: null,
      updateTime: 0.1, // seconds per update loop
      titleText: content[content.language].title,
      subtitleText: content[content.language].subtitle,
      messages: content[content.language].messages,
      signedURL: "",
      buttonTap: false,
      // DATA
      fileMeta: null,
      filesLength: 1,
      // AUDIO
      sourceArray: [], // for accessing context source node
      processData: [],
      requestArray: [], // array of requests for aborting
      fileIndex: 0,
      prevFileIndex: 0,
      isStuckOn: 0, // WIP index at which playback got stuck
      crossfadeTime: 0.3, // crossfade, if theres no margin in the audio file this can cause desync
      resyncDeltaThreshold: 0.2, // if delta greater than this, soft resync
      hardResyncDeltaThreshold: 10.0, // if delta is greater than this, force a hard resync
      hardResyncTimeout: null,
      playbackRateProxy: 1,
      // frameLoopReq: null,
      workerInterval: new Worker("../frameWorker.js"),
      frameCount: 0,
      reconnectInterval: "",
      isConnected: false,
    };
  },
  methods: {
    // WEBSOCKET
    send(type, content) {
      if (this.socket.readyState == 1) {
        const message = {
          action: type,
          content: content,
        };
        this.socket.send(JSON.stringify(message));
      }
    },
    update() {
      this.requestTimecode();
    },
    onConnected(callback) {
      //console.log("Connected to server.");
      this.isConnected = true;
      if (this.reconnectInterval != "") {
        clearInterval(this.reconnectInterval);
        this.reconnectInterval = "";
      }
      callback();
    },
    onClose() {
      //console.log("socket closed");
      this.isReady = false;
      this.workerInterval.postMessage("stop");
      for (let i = 0; i < this.filesLength; i++) {
        this.killAudio(i);
      }
      this.isConnected = false;
      clearInterval(this.updateInterval);
      this.reconnectInterval = setInterval(this.reconnect, 3000);
    },
    convertTime(time) {
      var sec_num = parseFloat(time, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    },
    async onMessage(event) {
      const message = JSON.parse(event.data);
      ////console.log(event.data);

      if (message.messageType === "getTime") {
        this.sendTimecodeRequest(message.lambdaTime, message.localTime);
      }

      if (message.messageType === "timecode") {
        var response = await fetch(process.env.VUE_APP_GETTIME_ENDPOINT, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            localTime: performance.now(),
          }),
        });

        var responseJSON = JSON.parse(await response.text());
        var timeObject = JSON.parse(responseJSON["body"]);
        const clientTime =
          parseInt(timeObject["lambdaTime"]) +
          (performance.now() - parseInt(timeObject["localTime"]));
        const serverTime = parseFloat(message.serverTime) || 0; // WIP fallbacks for test server

        const times = message.timecode.split(":");
        const hours = parseFloat(times[0]);
        const minutes = parseFloat(times[1]);
        const seconds = parseFloat(times[2]);
        const frame = parseFloat(times[3]);

        // var lambdaResponseTime = message.lambdaResponseTime * 1000
        // var lambdaRequestTime = message.lambdaRequestTime * 1000
        // var lambdaOffset = lambdaResponseTime - lambdaRequestTime

        // this is assuming 25 fps
        const milliseconds = (frame / 24.0) * 1000.0;

        this.time =
          hours * 60.0 * 60.0 +
          minutes * 60.0 +
          seconds +
          milliseconds / 1000.0;

        //const clientTime = parseFloat(message.clientTime) || 0;
        const lambdaOffset = (clientTime - serverTime) / 1000.0;
        if (clientTime > serverTime) this.time += lambdaOffset;
        //this.time += lambdaOffset;
      }

      this.lastUpdate = performance.now() / 1000;
    },
    reconnect() {
      if (window.navigator.onLine && !this.isConnected) {
        //console.log("reconnecting...");
        this.init();
      }
    },
    connect() {
      //console.log("connecting to ws");
      const self = this;
      return new Promise(function (resolve) {
        self.socket = new WebSocket(
          `${process.env.VUE_APP_WEBSOCKET_URL}?clientType=client`
        );
        self.socket.onopen = self.onConnected(() => {
          // connection callback
          resolve();
        });
        self.socket.onmessage = self.onMessage;
        self.socket.onclose = self.onClose;
      });
    },
    join() {
      clearInterval(this.updateInterval);
      this.update();
      this.updateInterval = setInterval(this.update, this.updateTime * 1000);
    },
    onOpen() {
      //console.log("connected");
    },
    toggleButton() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        if (!this.started) this.runStart();
      } else {
        if (this.started) this.runStop();
      }

      this.buttonTap = true;
      setTimeout(() => {
        this.buttonTap = false;
      }, 50);
    },

    // DATA
    async setMetadata() {
      const self = this;
      var signedURLResponse = await fetch(
        process.env.VUE_APP_GEOLOCK_ENDPOINT,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            //latitude: coords.latitude,
            //longitude: coords.longitude,
            path: "/meta.json",
            action: "getAudio",
          }),
        }
      );

      var signedURLJSON = await signedURLResponse.json();
      if (signedURLJSON["status"] == 403) {
        return;
      }
      var signedURL = signedURLJSON["body"].replaceAll('"', "");
      return new Promise(function (resolve) {
        fetch(signedURL)
          .then((resp) => {
            return resp.json();
          })
          .then((json) => {
            self.fileMeta = json;
            self.filesLength = json.files.length;
            self.crossfadeTime = json.crossfadeTime;
            // populate buffer
            json.files.forEach(() => {
              self.processData.push(self.processDataFactory());
              self.requestArray.push("");
            });
            //console.log(self.fileMeta);
            resolve();
          });
      });
    },
    initAudioEl() {
      this.fileMeta.files.forEach(() => {
        const source = new Audio();
        source.src = require("../audio/silence.mp3");
        source.load();
        source.controls = true;
        source.loop = true;
        source.crossOrigin = "anonymous";
        source.addEventListener(
          "canplaythrough",
          () => {
            source.play(); // ride this one play event for entire experience
          },
          { once: true }
        );
        source.addEventListener("pause", this.onPauseChange);
        source.addEventListener("play", this.onPauseChange);
        if (this.$root.showDebug) {
          this.$refs.debug.appendChild(source);
        }
        this.sourceArray.push(source);
      });
    },

    // AUDIO
    calcIndex() {
      let sum = 0;
      this.prevFileIndex = this.fileIndex;
      for (let i = 0; i < this.fileMeta.files.length; i++) {
        const file = this.fileMeta.files[i];
        sum += file.duration;
        if (sum >= this.time) {
          this.fileIndex = i;
          break;
        }
      }
      //console.log(`index set at ${this.fileIndex}`);
    },
    calcIndexAt(time = this.syncedRunningTime) {
      let sum = 0;
      let index = this.fileMeta.files.length - 1;
      for (let i = 0; i < this.fileMeta.files.length; i++) {
        const file = this.fileMeta.files[i];
        sum += file.duration;
        if (sum >= time) {
          index = i;
          break;
        }
      }
      return index;
    },
    calcRelativeTime(index = this.fileIndex) {
      let sum = 0;
      const dur = this.fileMeta ? this.fileMeta.totalDuration : Infinity;
      for (let i = 0; i < index; i++) {
        const file = this.fileMeta.files[i];
        sum += file.duration;
      }
      return (
        (this.time - sum + (performance.now() / 1000 - this.lastUpdate)) % dur
      );
    },
    calcTotalLocalTime(time, index = this.fileIndex) {
      let sum = 0;
      for (let i = 0; i < index; i++) {
        const file = this.fileMeta.files[i];
        sum += file.duration;
      }
      return time + sum;
    },
    async loadAudio(index) {
      //console.log(`loading ${index}`);

      // init audio context
      this.processData[index] = this.processDataFactory();
      this.processData[index].duration = this.fileMeta.files[index].duration;
      this.requestArray[index] =
        typeof AbortController === "function" ? new AbortController() : "";
      const signal = this.requestArray[index]
        ? this.requestArray[index].signal
        : null;
      // promise
      const self = this;
      var signedURLResponse = await fetch(
        process.env.VUE_APP_GEOLOCK_ENDPOINT,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            //latitude: coords.latitude,
            //longitude: coords.longitude,
            path: `/out-${index}`,
            action: "getAudio",
          }),
        }
      );

      var signedURLJSON = await signedURLResponse.json();
      if (signedURLJSON["status"] == 403) {
        return;
      }
      var signedURL = signedURLJSON["body"].replaceAll('"', "");
      if (signedURL["status"] == 403) return;
      return new Promise(function (resolve) {
        fetch(signedURL, { signal: signal })
          .then((resp) => {
            return resp.arrayBuffer();
          })
          .then((buffer) => {
            //console.log(`decoding ${index}`, buffer);
            // decrypt here
            const decomp = fflate.unzlibSync(new Uint8Array(buffer));
            const arr = self.decryptAudio(decomp);
            // create source node and connect everything
            const file = new File([arr], `out-${index}.mp3`, {
              type: "audio/mpeg",
            });
            const url = URL.createObjectURL(file);
            self.sourceArray[index].src = url;
            self.sourceArray[index].load();
            self.sourceArray[index].volume = 0;
            self.processData[index].duration =
              self.fileMeta.files[index].duration;

            //console.log(`assigned source ${index}`, self.sourceArray[index]);

            // tab inactive end fallback, sucks but better than nothing
            self.sourceArray[index].addEventListener(
              "ended",
              () => {
                self.sourceArray[index].muted = true; // fake one shot
                self.runStop();
                // if hasnt changed yet
                if (self.fileIndex == index) {
                  self.onEnd(index);
                }
              },
              { once: true }
            );

            self.sourceArray[index].addEventListener(
              "canplaythrough",
              () => {
                //console.log(`source ${index} playable`);

                if (self.processData[index].isStuck) {
                  //console.log(`${index} unstuck`);
                  self.doPlay(index, 0);
                  self.processData[index].isStuck = false;
                }
                //console.log(`${index} RESOLVED`);

                self.sourceArray[index].muted = true;
                resolve();

                if (self.sourceArray[index].paused)
                  self.sourceArray[index].play(); // non ios
              },
              { once: true }
            );
          })
          .catch((err) => {
            console.error(err);
            // retry load again
            self.loadAudio(index).then(() => {
              resolve();
            });
          });
      });
    },
    decryptAudio(arraybuffer) {
      const arr = new Uint8Array(arraybuffer);
      return arr.reverse();
    },
    queuePlayAudio(index, offset) {
      if (!this.started) return;
      if (this.sourceArray[index].src) {
        this.doPlay(index, offset);
      } else {
        //console.log(`${index} is still loading!!! queueing play`);
        this.processData[index].isStuck = true;
        this.isStuckOn = index;
      }
    },
    doPlay(index, offset) {
      if (
        index === 0 &&
        this.prevFileIndex === this.fileMeta.files.length - 1 &&
        !this.resetLoopTimeout
      ) {
        this.avgDeltaArr = [];
        this.resetLoopTimeout = setTimeout(() => {
          //console.log("Resetted loop");
          this.resetLoop = false;
          clearTimeout(this.resetLoopTimeout);
          this.resetLoopTimeout = null;
        }, 3000);
      }

      //console.log(`playing ${index} at ${offset}`);
      const newOffset = this.clamp(offset, 0, this.processData[index].duration);

      if (!this.processData[index].isPlaying) {
        this.sourceArray[index].currentTime = newOffset;

        if (this.sourceArray[index].paused) this.sourceArray[index].play(); // non ios

        if (this.isPlaying) this.fadeAudio(index, 0, 1, this.crossfadeTime);
        this.processData[index].isPlaying = true;
      }
      //console.log("process data");
      //console.log(this.processData);
      //console.log("sourceArray: ");
      //console.log(this.sourceArray);
    },
    killAudio(index) {
      if (this.requestArray[index]) this.requestArray[index].abort(); // abort fetch

      if (this.processData[index])
        this.fadeAudio(
          index,
          this.sourceArray[index] ? this.sourceArray[index].volume : 1,
          0,
          this.crossfadeTime
        );

      setTimeout(() => {
        if (this.sourceArray[index]) {
          this.sourceArray[index].muted = true;
          this.sourceArray[index].src = require("../audio/silence.mp3");
          this.sourceArray[index].load();
        }

        this.processData[index] = this.processDataFactory();
        this.requestArray[index] = "";

        //console.log(`killed ${index}`);
      }, this.crossfadeTime * 1000);
    },
    hardReset() {
      // for when desync is unreasonable
      //console.log("HARD RESET");

      this.delta = 0;
      this.isReady = false;
      this.calcIndex();

      this.workerInterval.postMessage("stop");
      this.killAudio(this.prevFileIndex);
      this.killAudio(this.fileIndex);

      var nextIndex;

      if (this.fileIndex === this.fileMeta.files.length - 1) {
        nextIndex = 0;
      } else nextIndex = this.limitIndex(this.fileIndex + 1);

      return Promise.all([
        this.loadAudio(this.fileIndex),
        this.loadAudio(nextIndex),
      ]).then(() => {
        this.isReady = true;
        this.queuePlayAudio(this.fileIndex, this.calcRelativeTime());
        this.workerInterval.postMessage("start");
        this.workerInterval.onmessage = this.frameLoop;
      });
    },
    requestTimecode() {
      this.send("getTime", {
        localTime: performance.now(),
      });
    },
    sendTimecodeRequest(lambdaTime, localTime) {
      this.send("requestTimecode", {
        clientTime: lambdaTime + (performance.now() - localTime) / 2,
        performanceTime: performance.now(),
        env: process.env.VUE_APP_ENVIRONMENT,
      });
    },
    frameLoop() {
      // ALWAYS
      const process = this.processData[this.fileIndex];
      const source = this.sourceArray[this.fileIndex];
      const relativeTime = this.calcRelativeTime();

      this.syncedRunningTime = this.clamp(
        this.time +
          (performance.now() / 1000 - this.lastUpdate) -
          this.crossfadeTime,
        0,
        this.fileMeta.totalDuration
      );

      if (this.started) {
        this.delta =
          this.syncedRunningTime -
          this.calcTotalLocalTime(process.trueCurrentTime); // get delta in global time space in case delay wraps around
        process.trueCurrentTime = source.currentTime;
      } else {
        this.delta = 0;
      }

      // avg delta every 12 frames
      if (
        this.frameCount % 12 == 0 &&
        Math.abs(this.delta) < this.hardResyncDeltaThreshold &&
        this.isPlaying
      ) {
        // 5 second buffer
        if (this.avgDeltaArr.length >= 25) {
          this.avgDeltaArr.shift();
        }
        this.avgDeltaArr.push(this.delta);

        if (this.resyncDelayDeltaArr.length >= 25) {
          this.resyncDelayDeltaArr.shift();
        }
        this.resyncDelayDeltaArr.push(this.delta);

        // calc avgDelta
        let deltaSum = 0;
        this.avgDeltaArr.forEach((d) => {
          deltaSum += d;
        });
        this.avgDelta = deltaSum / this.avgDeltaArr.length;

        // calc resyncDelta
        let resyncDeltaSum = 0;
        this.resyncDelayDeltaArr.forEach((d) => {
          resyncDeltaSum += d;
        });
        this.resyncDelayDelta =
          resyncDeltaSum / this.resyncDelayDeltaArr.length;
      }

      // gain lerp
      this.updateGainLerp(this.prevFileIndex);
      this.updateGainLerp(this.fileIndex);

      // onEnd hook, always run on server time
      const delay =
        this.fileIndex == this.filesLength - 1 ? 0 : this.crossfadeTime;
      const duration = this.fileMeta.files[this.fileIndex].duration;
      if (relativeTime >= duration - delay) {
        if (
          !(
            relativeTime > this.fileMeta.totalDuration - 2 &&
            this.fileIndex == 0
          )
        )
          this.onEnd(this.fileIndex);
      }

      // extreme resync, if delta is greater than hardResyncDeltaThreshold for more than updateTime
      // use delta with timeout to catch outliers
      if (
        Math.abs(this.delta) >= this.hardResyncDeltaThreshold &&
        !this.hardResyncTimeout &&
        !this.resetLoop
      ) {
        //console.log("LARGE DESYNC");
        this.hardResyncTimeout = setTimeout(() => {
          this.hardReset();
        }, this.updateTime * 1000 * 3);
      } else {
        if (
          Math.abs(this.delta) < this.hardResyncDeltaThreshold &&
          this.hardResyncTimeout
        ) {
          //console.log("LARGE DESYNC CANCEL");
          clearTimeout(this.hardResyncTimeout);
          this.hardResyncTimeout = null;
        }

        // normal resync
        if (this.started) {
          // resync
          if (
            Math.abs(this.avgDelta) > this.resyncDeltaThreshold &&
            this.frameCount % 180 == 0
          ) {
            // source.currentTime = relativeTime;
            if (
              source.currentTime + this.avgDelta < source.duration &&
              source.currentTime + this.avgDelta >= 0
            ) {
              //console.log("resyncing", this.avgDelta);
              const delayDelta = this.clamp(this.resyncDelayDelta, 0, 2);
              source.currentTime = this.clamp(
                source.currentTime + this.avgDelta + delayDelta,
                0,
                source.duration
              ); // if it takes your device more than 2 seconds to seek something is seriously wrong
              // if (source.paused) source.play();
              this.avgDeltaArr = [];
              this.avgDeltaArr.forEach((d, i) => {
                this.avgDeltaArr[i] = 0;
              });
            }
            // this.$refs.test.currentTime = this.syncedRunningTime
          }
        }
      }

      this.frameCount++;
    },
    updateGainLerp(index) {
      const gainLerp = this.processData[index].gainLerp;

      if (gainLerp.isLerp) {
        if (!gainLerp.step) {
          // run once
          gainLerp.step = 0.0167 / gainLerp.time;
          // hard set for slowdowns or ios not having volume lol
          if (gainLerp.to > 0) this.sourceArray[index].muted = false;
          clearTimeout(gainLerp.hardTimeout);
          gainLerp.hardTimeout = setTimeout(() => {
            gainLerp.perc = 1;
            if (gainLerp.to == 0) this.sourceArray[index].muted = true;
          }, gainLerp.time * 1000);
        }

        gainLerp.perc += gainLerp.step;
        gainLerp.perc = this.clamp(gainLerp.perc, 0, 1);

        if (gainLerp.perc >= 1) {
          // lerp over
          clearTimeout(gainLerp.hardTimeout);
          this.sourceArray[index].volume = gainLerp.to;
          gainLerp.isLerp = false;
          gainLerp.step = 0;
          gainLerp.perc = 0;
        } else {
          // set vol
          this.sourceArray[index].volume = this.lerp(
            gainLerp.from,
            gainLerp.to,
            gainLerp.perc
          );
        }
      }
    },
    onEnd(index) {
      //console.log(`${index} ended`);
      this.prevFileIndex = index;
      this.fileIndex = this.limitIndex(this.fileIndex + 1);
      var nextIndex;

      if (index === this.fileMeta.files.length - 1) {
        this.fileIndex = 0;
        this.resetLoop = true;
      }

      if (index === this.fileMeta.files.length - 2) {
        nextIndex = 0;
      } else nextIndex = this.limitIndex(this.fileIndex + 1);

      //console.log(`switched to ${this.fileIndex}`);

      if (this.isPlaying && this.started)
        this.queuePlayAudio(this.fileIndex, 0);
      this.loadAudio(nextIndex); // start next

      this.killAudio(this.prevFileIndex);
    },

    // UTIL
    limitIndex(index) {
      let newIndex = index % this.filesLength;
      return newIndex < 0 ? this.filesLength - 1 + newIndex : newIndex;
    },
    clamp(value, min, max) {
      return Math.max(Math.min(value, max), min);
    },
    lerp(start, stop, amt) {
      return amt * (stop - start) + start;
    },
    processDataFactory() {
      return {
        duration: 0,
        trueCurrentTime: 0,
        isPlaying: false,
        isStuck: false,
        gainLerp: {
          isLerp: false,
          from: 0,
          to: 0,
          time: this.crossfadeTime,
          step: 0,
          perc: 0,
          hardTimeout: null,
        },
      };
    },
    runStart() {
      //console.log("runStart");
      this.started = true;
      this.queuePlayAudio(this.fileIndex, this.calcRelativeTime());
    },
    runStop() {
      //console.log("runStop");
      this.started = false;
      this.processData[this.fileIndex].isPlaying = false;
      this.fadeAudio(this.fileIndex, 1, 0, 0.2);
    },
    runHardStop() {
      this.isPlaying = false;
      this.runStop();
      for (let i = 0; i < this.filesLength; i++) {
        this.killAudio(i);
      }
    },
    fadeAudio(index, from, to, time) {
      this.processData[index].gainLerp.isLerp = true;
      this.processData[index].gainLerp.from = from;
      this.processData[index].gainLerp.to = to;
      this.processData[index].gainLerp.time = time;
    },
    init() {
      const self = this;
      if (this.sourceArray.length == 0) this.initAudioEl();
      this.connect()
        .then(() => {
          // poll websocket until it is actually ready
          const websocketPoll = setInterval(() => {
            //console.log(this.socket.readyState);
            if (this.socket.readyState === 1) {
              this.join();
              clearInterval(websocketPoll);
            }
          }, 100);

          // wait for time to be valid
          return new Promise(function (resolve) {
            const unwatch = self.$watch("time", () => {
              //console.log("time update");
              if (!self.time && self.time !== 0) return;
              resolve();
              unwatch();
            });
          });
        })
        .then(() => {
          // get index
          this.calcIndex();
          var nextIndex;

          if (this.fileIndex === this.fileMeta.files.length - 1) {
            nextIndex = 0;
          } else nextIndex = this.limitIndex(this.fileIndex + 1);

          return Promise.all([
            this.loadAudio(this.fileIndex),
            this.loadAudio(nextIndex),
          ]);
        })
        .then(() => {
          this.workerInterval.postMessage("start");
          this.workerInterval.onmessage = this.frameLoop;
          this.isReady = true;
          //console.log("init done");
        });
    },
    reloadPage() {
      window.removeEventListener("online", this.reloadPage);
      this.$router.go();
    },
    onVisibilityChange() {
      //console.log("visibility change", document.visibilityState);
      if (document.visibilityState === "hidden") this.runHardStop();
    },
    onPauseChange(e) {
      //console.log(e.type, e);
      if (e.type === "pause") this.runHardStop();
    },
  },
  mounted() {
    if (window.navigator.onLine) {
      this.setMetadata().then(() => {
        this.$emit("openmodal", this.messages.audioSyncReady, this.init);
      });
    } else {
      window.addEventListener("online", this.reloadPage);
    }
    document.addEventListener("visibilitychange", this.onVisibilityChange);
  },
  beforeUnmount() {
    clearInterval(this.updateInterval);
    this.workerInterval.postMessage("stop");
    for (let i = 0; i < this.filesLength; i++) {
      this.killAudio(i);
    }
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },
};
</script>

<style lang="scss">
.home {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 1rem;

  max-width: $b-lg;
  @media (max-width: $b-lg) {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__header {
    width: 100%;

    &__subtitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__icon {
        fill: $white-fade;
        margin-right: 0.4rem;
      }

      &__text {
        display: inline-block;
        color: $white-fade;
      }
    }

    &__title {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    width: 100%;
    height: 30vh;
    max-height: 30vh;
    padding: 0 1rem 0 3rem;
    transform: translateY(calc(-50% - 2rem));
    pointer-events: none;

    img {
      height: 100%;
      width: auto;
      max-width: 75vw;
      margin-left: auto;
      object-fit: contain;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    pointer-events: none;

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 8rem;
      height: 8rem;
      min-width: 8rem;
      min-height: 8rem;
      background-color: $white;
      border-radius: 100%;
      transform: scale(1);
      transition: transform $d-short $ease-in-out,
        background-color $d-short $ease-in-out;
      pointer-events: auto;

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(1);
      }

      &__icon {
        width: 2.5rem;
        height: 2.5rem;
        background: $black;
        clip-path: polygon(0 0, 100% 50%, 100% 50%, 0 100%);
        margin-left: 0.5rem;
        transition: clip-path $d-short $ease-in-out,
          margin-left $d-short $ease-in-out, opacity $d-standard $ease-in-out;
      }

      &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4rem;
        height: 4rem;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: opacity $d-standard $ease-in-out;

        &__svg {
          animation: spinner-rotate $d-longest linear infinite;
          width: 100%;
          height: 100%;

          .path {
            stroke: $black;
            animation: spinner-length $d-longest ease-in-out infinite;
          }
        }

        @keyframes spinner-rotate {
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes spinner-length {
          0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -30;
          }
          100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
          }
        }
      }

      &--tap {
        transform: scale(1.05);
      }

      &--stop {
        .home__controls__btn__icon {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          margin-left: 0;
        }
      }

      &--loading {
        background-color: $white-fade;
        pointer-events: none;

        .home__controls__btn__icon {
          opacity: 0;
        }

        .home__controls__btn__spinner {
          opacity: 1;
        }
      }
    }
  }

  // router transitions

  transform: translateX(0rem);
  transition: opacity $d-standard $ease-in-out;

  .home__header {
    transform: translateX(0rem);
    opacity: 1;
    transition: opacity $d-long $ease-in-out 0ms,
      transform $d-long $ease-in-out 0ms;
  }

  .home__image__img {
    transform: translateX(0rem);
    opacity: 1;
    transition: opacity $d-longer $ease-in-out 200ms,
      transform $d-longer $ease-in-out 200ms;
  }

  &.router-trans-enter-active {
    opacity: 0;

    .home__header {
      opacity: 0;
      transform: translateX(-1rem);
    }

    .home__image__img {
      opacity: 0;
      transform: translateX(2rem);
    }
  }
  &.router-trans-leave-active {
    opacity: 0;
  }
}
</style>
