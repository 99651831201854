<template>
  <div class="loading">
    <div
      class="loading__spinner"
      :class="{ 'loading__spinner--done': !isLoading }"
    >
      <svg class="loading__spinner__svg" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
  </div>
</template>

<script>
import { content } from "../js/contentStore.js";

export default {
  name: "Loading",
  data() {
    return {
      isLoading: true,
      messages: content[content.language].messages,
    };
  },
  methods: {
    locationCallback(location = false) {
      if (location) {
        //console.log("location granted");
        this.$emit("checkregion", this.regionLockCallback, location);
      } else {
        //console.log("location not available");
        this.$emit("openmodal", this.messages.locationPermissionDenied);
        this.isLoading = false;
      }
    },
    regionLockCallback(inRange) {
      if (inRange) {
        //console.log("region valid");
        this.$router.push({ name: "Home" });
        this.isLoading = false;
      } else {
        //console.log("region invalid");
        this.$emit("openmodal", this.messages.locationRegionInvalid);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    //this.$emit("getlocation", this.locationCallback);
    this.regionLockCallback(true);
  },
};
</script>

<style lang="scss">
.loading {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 1rem;

  max-width: $b-lg;
  @media (max-width: $b-lg) {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
    transition: opacity $d-standard $ease-in-out;

    &--done {
      opacity: 0;
    }

    &__svg {
      animation: spinner-rotate $d-longest linear infinite;
      width: 100%;
      height: 100%;

      .path {
        stroke: $white-fade;
        animation: spinner-length $d-longest ease-in-out infinite;
      }
    }

    @keyframes spinner-rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes spinner-length {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -30;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  }

  transition: opacity $d-standard ease-in-out;

  // router transitions
  &.router-trans-enter-active {
    opacity: 0;
  }
  &.router-trans-leave-active {
    opacity: 0;
  }
}
</style>
